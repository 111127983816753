﻿
/* --------------------------------- */
/* ==== High Contrast Overrides ==== */
/* --------------------------------- */
html.access-colour-contrast { background-color: #000 !important;

	body { background-color: #000 !important; }

	h1, h2, h3, h4, h5, h6, p, span, li, td { color: yellow !important; }

	.switch.small .switch-paddle { background-color: #000 !important; border: 1px solid yellow !important;
		span { color: yellow !important; }
		&::after { background-color: #000; }
	}

	.btn-txt-size { border-color: yellow; color: yellow;
		&.active { background-color: yellow; color: #000; }
	}

	.btn-demo { border-color: yellow; color: yellow;
		&:hover { background-color: yellow; color: #000; border-color: yellow; }
	}

	* { background-color: #000 !important; color: yellow !important; }
	.grid-container, .grid-x, .cell { background-color: transparent !important; }
}


/* --------------------------------- */
/* ==== Sepia Contrast Overrides ==== */
/* --------------------------------- */
html.access-colour-sepia { background-color: #cc9 !important;

	body { background-color: #cc9 !important; }

	h1, h2, h3, h4, h5, h6, p, span, li, td { color: #009 !important; }

	.switch.small .switch-paddle { background-color: #cc9 !important; border: 1px solid #009 !important;
		span { color: #009 !important; }
		&::after { background-color: #000; }
	}

	.btn-txt-size { border-color: #009; color: #009;
		&.active { background-color: #009; color: #cc9; }
	}

	.btn-demo { border-color: #009; color: #009;
		&:hover { background-color: #009; color: #000; border-color: #009; }
	}

	* { background-color: #cc9 !important; color: #009 !important; }
	.grid-container, .grid-x, .cell { background-color: transparent !important; }
}
