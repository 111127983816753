
body{
  overflow-x: hidden;
  position: relative;


}

//img.competition-splash {
//  display: none;
//}

//@media only screen and (min-width: 81.063em) {
//
//  body {
//    img.competition-splash {
//      position: fixed;
//      right: 0px;
//      top: 5px;
//      max-width: 265px;
//      overflow: hidden;
//      width: 20ch;
//      display: block;
//    }
//  }
//}


// Wrappers
.main-wrapper{
  max-width: 940px;
  margin: 0 auto;
}
.head-wrapper{
  background: #fff url(../../../public/img/layout/bg-ball.jpg) no-repeat center right -50px;
  padding: 20px 30px;
  position: relative;
  .title{
    color: $brand-blue;
    line-height: 1;
    font-weight: bold;
    font-size: 3.6rem;
    letter-spacing: 1px;
    margin-top: 20px;
  }
}
.main-bg{
  background: #19395c url(../../../public/img/layout/bg-content.png) repeat-x top center;
  padding-top: 70px;
  padding-bottom: 20px;
}

.homepage .main-bg{
  padding-bottom: 0px;
}

.white-bg{
  background-color: #fff;
  padding: 40px 20px;
  h2{
    font-weight: 400;
    font-size: 1.6rem;
    color: $brand-blue;
  }
  h1, h3, h4, h5, h6, p{
    color: $brand-grey;
  }
  a{
    transition: .4s ease;
    &:hover{
      text-decoration: underline;
    }
  }
}

.container-wrapper {
  margin-left: 20px;
  padding: 20px;
  position: relative
}

.content-wrapper{
  background-color: #fff;
  margin-left: 20px;
  padding: 20px;
  position: relative;
  padding-bottom: 50px;

  &.nopb {
    padding-bottom: 15px;
  }

  h2{
    font-weight: 400;
    font-size: 1.6rem;
    color: $brand-blue;
  }
  form{
    margin-left: 30px;
    margin-right: 30px;
    color: $brand-grey;
  }
  .info-alert{
    margin: 30px auto;
  }
  a{
    transition: .4s ease;
    &:hover{
      text-decoration: underline;
    }
  }

  article.zestypage {
    ol {
      li {
        font-weight: 200;
      }
    }
  }

}
.side-wrapper{
  position: relative;
  color: #fff;
  .title-orange{
    color: #F38046;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    line-height: 1.2;
  }
  span{
    font-size: 0.75em;
  }
  p{
    margin-bottom: 5px;
    color: #fff;
  }
  .btn-green{
    margin-bottom: 20px;
  }

}
.home-section{
  color: #fff;
  padding: 0 15px 20px 15px;
  position: relative;
  .row{
    margin-bottom: 20px;
  }
  a{
    color: #fcbd29;
    font-size: .9rem;
    transition: .3s ease;
    &:hover{
      text-decoration: underline;
    }
  }
  p{
    // font-size: .9rem;
    color: #fff;
  }
  h2, h3{
    color: #fff;
  }
  .find-camp{
    background: url(../../../public/img/layout/top-panel-divider.png) no-repeat left center;
    padding: 0 25px;
    p{
      margin: 0 1em 1em 0;
    }
    .btn-green{
      width: 160px;
      margin-top: .5em;
      margin-bottom: .5em;
      font-size: .8rem;
      border: 2px solid #113450;
      box-shadow: 0 0 5px rgba(0,0,0,.2);
      padding-top: 8px;
      padding-bottom: 8px;
      display: block;
    }
  }
}
.competition-wrapper{
  img{
    margin: 30px 0 10px 0;
  }
  padding: 15px;
}
.blue-wrapper{
  background-color: #115d78;
  padding: 20px;
  label{
    color: #fff;
    padding-top: 0;
  }
  input[type="text"], input[type="number"]{
    /*max-width: 90px;*/
  }
}

// Alert Messages

div.messages {
  position: absolute;
  width: 100%;
  left:0;
  top: 0;
  z-index: 99999;

  .callout {
    border-radius: 4px;

    /* Danger Alert Error - All Basically a big red warning */
    &.danger, &.alert, &.error {
      background: #b92c28;
      border-color: darken(#b92c28, 30);
      color : #ffffff;
      a {
        color: #ffffff
      }
    }

    &.warning{
      background: #ffba0f;
      border-color: darken(#ffba0f, 30);
      color : #000000;
    }
    &.success {
      border-color: darken(#758c33, 30);
      background: #758c33;
      background-image: -webkit-linear-gradient(top, #758c33, #467a2f);
      background-image: -moz-linear-gradient(top, #758c33, #467a2f);
      background-image: -ms-linear-gradient(top, #758c33, #467a2f);
      background-image: -o-linear-gradient(top, #758c33, #467a2f);
      background-image: linear-gradient(to bottom, #758c33, #467a2f);
      color: #ffffff;
    }
  }

}

// Form styles


.camp-fieldset,
.attendee-fieldset {
  border-top: solid 1px $brand-lightgrey;
  margin-bottom: 50px;
  padding: 100px 20px 50px 20px;
  //padding-bottom: 2px !important;
  background: #fff;
}

form{
  label{
    font-weight: 600;
    font-size: 1rem;
    font-family: $font-main;
    color: #55585b;
    padding-top: 10px;
    line-height: 1.1;
  }
  .columns{
    margin-bottom: 15px;
  }
  .help-text{
    margin-top: 3px;
    color: #999999;
  }
  span{
    font-size: .9em;
    color: #999999;

    .help,
    &.smallprint {
      color: #808080;
    }
  }

  div.ks {
    display: block;
    clear: both;
    min-height: 50px;
  }

  div.radio {
    clear: both;
    label {
      display: block !important;
      margin-bottom: 0.5rem;
      min-width: 2rem;
      font-weight: normal;
      font-size: 0.8em;
    }
    div.radio input {
      &:active, &:focus, &:hover {
        outline: none !important;
      }
    }
  }

  p.small {
    margin-top:0.2em;
    font-size:0.9em;
    color: #808080;
  }

  small.error {
    color: #ff0000;
    font-size:0.8em;
    background: #FFDADC;
    padding:2px;
  }
  .btn-green{
    padding: 15px;
    margin-top: 20px;
  }
  //input[type="text"],
  //input[type="email"],
  //input[type="password"],
  //textarea{
  //  height: 68px;
  //  -webkit-border-radius: 7px;
  //  -moz-border-radius: 7px;
  //  border-radius: 7px;
  //  vertical-align: middle;
  //  margin-bottom: 5px;
  //}
}
form.booking{
  div.selector.fixedWidth{
    width: 100%;
    span{
      width: auto;
    }
  }
}

.search-panel{

  background: #7cafc5;
  background-image: none;
  @include borderRadius(12px);
  //background-image: linear-gradient(0deg, #7cafc5,#4d6d85);
  //@include linearGradient(  #4d6d85, #7cafc5 );
@include linearGradient(#27475d, #375f7d);

  .search-container{
    padding-right: 0;
    background: url(../../../public/img/layout/top-panel-divider-small.png) no-repeat right top;
    padding-top: 30px;
    padding-bottom: 15px;
    text-align: center;
    p{
      color: #fff;
      font-size: .9em;
    }
    div.selector.fixedWidth{
      width: auto;
      span{
        width: 230px;
      }
    }
  }
  .search-date{
    padding-left: 0;
    padding-right: 20px;
    padding-right: 0;
    padding-top: 30px;
    padding-bottom: 15px;
  }

  .search-inclusive{
    padding-top: 0px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: -19px;

    p{
      color: #fff;
      font-size: .9em;
      display: inline-block;
      margin-right: 15px;
    }
    .switch {
      display: inline-grid;
      bottom: -0.625rem;
      label.switch-paddle {
        background: #d7d7d7;
      }
      input:checked ~ .switch-paddle {
        background: #b4e0b4 !important;
      }
    }
  }

  .btn-green{
    padding: 7px 15px;
    margin-right: 40px;
    margin-top: -10px;
    margin-bottom: 20px;
    .search-icon{
      background: url(../../../public/img/layout/icon-search.png) no-repeat center center;
      display: inline-block;
      height: 16px;
      // margin-left: 4px;
      margin-right: 4px;
      vertical-align: middle;
      width: 14px;
    }
  }
}
.county-info{
  color: $brand-grey;
  font-size: .85em;
  tr{
    background-color: #fff !important;

    th{
      width: 40%;
      text-align: right;
      vertical-align: top;
    }
    td{
      width: 60%;
    }
  }
}
#results{
  margin-top: 20px;
  color: $brand-blue;
  text-align: center;
  font-weight: bold;
  tbody tr{
    transition: .3s ease;
    &:hover{
      background-color: #f2855f;
      color: #000;
    }
  }
  thead tr th{
    text-align: center;
  }
  .btn-booking{
    display: block;
    padding: 5px;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    font-weight: 400;
    color: #333333;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    background-color: #f5f5f5;
    background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
    background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border: 1px solid #cccccc;
    border-bottom-color: #b3b3b3;
    border-radius: 4px;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  }
}

// Buttons
.btn-book{
 border-radius: 5px;
 font-size: 2.8em !important;
 font-family: $font-title;
 padding: 25px 15px 15px 20px;
 line-height: .3;
 letter-spacing: 2px;
 text-decoration:none !important;
 display:inline-block;
 font-weight:bold;
 color: #fff !important;
 margin: 20px 5px 5px 5px;
 text-transform: uppercase;
 background-color: #6CA4CB;
 background-image: -webkit-gradient(linear, left top, left bottom, from(#6CA4CB), to(#3B6989));
 background-image: -webkit-linear-gradient(top, #6CA4CB, #3B6989);
 background-image: -moz-linear-gradient(top, #6CA4CB, #3B6989);
 background-image: -ms-linear-gradient(top, #6CA4CB, #3B6989);
 background-image: -o-linear-gradient(top, #6CA4CB, #3B6989);
 background-image: linear-gradient(to bottom, #6CA4CB, #3B6989);
 span{
   font-size: .5em;
   display: block;
   &:after{
     content: url(../../../public/img/layout/btn-arrow.png);
     padding-left: 8px;
     position: relative;
     bottom: -8px;
   }
 }
 &:hover{
   color: #fff;
 }
}
.btn-green{
  background: #758c33;
  background-image: -webkit-linear-gradient(top, #758c33, #467a2f);
  background-image: -moz-linear-gradient(top, #758c33, #467a2f);
  background-image: -ms-linear-gradient(top, #758c33, #467a2f);
  background-image: -o-linear-gradient(top, #758c33, #467a2f);
  background-image: linear-gradient(to bottom, #758c33, #467a2f);
  display: inline-block;
  font-family: Montserrat,sans-serif;
  font-size: 1.25rem;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
  color: #fff;
  border-radius: 5px;
  padding: 1rem 1.2rem;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.25);
  cursor: pointer;
  transform: translateZ(0);
  backface-visibility: hidden;
  transition: transform .3s cubic-bezier(.09,.655,.305,1.65) .1s,-webkit-transform .3s cubic-bezier(.09,.655,.305,1.65) .1s;
  &:hover {
    color: #f7f7f7;
  }
}
.btn-book-mobile{
  position: absolute;
  padding: 0 25px;
  color: $brand-grey !important;
  font-family: $font-title;
  font-size: 1.6rem !important;
  right: 0;
  top: 0;
  text-transform: uppercase;
  box-shadow: -5px 2px 5px rgba(0,0,0,.3);
  background: #ffbf09;
  background: -moz-linear-gradient(left,#ffbf09 0,#ff5929 100%);
  background: -webkit-linear-gradient(left,#ffbf09 0,#ff5929 100%);
  background: linear-gradient(to right,#ffbf09 0,#ff5929 100%);
}

// Homepage slideshow
ul#slideshow {
  margin-left: 3em;
  margin-top: 1em;
  background: url(../../../public/img/layout/home-photos.png) no-repeat;
  margin-bottom: 2em;
  list-style: none;
  margin-right: -20px;
  width: 206px;
  height: 222px;
  > li {
    height: 186px;
    width: 166px;
    margin-top: 17px;
    margin-bottom: 0;
    margin-left: 19px;
    > img {
      width: 100%;
      height: 100%;
      border: 4px solid #fafafa;
    }
  }
}

// Cards
.column-card{
  padding: 20px 15px;
  h3{
    font-family: $font-title;
    color: $brand-orange;
    a {
      color: $brand-orange;
      transition: .3s ease;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  p{
    font-size: .9rem;
    margin-right: 25px;
  }
}
.news-panel{
  margin: 20px 5px;
  .title{
    color: #fff;
    font-family: $font-title;
    font-size: 1.7em;
    padding-top: 5px;
  }
  .item{
    padding: 8px 20px;
    background: url(../../../public/img/layout/news-panel.jpg) no-repeat;
    min-height: 106px;
    a{
      color: #000 !important;
      font-family: $font-title !important;
      font-size: 1.7rem !important;
      padding: 0;
      margin: 0;
      display: block;
    }
  }
}
.tip-card{
  background-color: #f8f8f8;
  border: 1px solid #dcdcdc;
  padding: 10px;
  max-width: 200px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 0px 20px -5px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 20px -5px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 20px -5px rgba(0,0,0,0.5);
}


// REFUNDS

body.refund {

    div.errors {
      color: #ff0000;
      font-size:0.8em;
      background: #FFDADC;
      padding:2px;
    }

  div.refund-wrapper {
    span.fa-check-circle {
      color: #608135;
      font-size: 1.55em;
      margin-right: 7px;
      vertical-align: top;


      div.info-alert{
        background: #f2f2f2 url('../../../public/img/layout/icon-alert.png') no-repeat left 15px top 1em;
        padding: 1em 1em 1em 5.5em;
        border-radius: 2px;
        box-shadow: 0 0 5px rgba(0,0,0,0.3);
        margin-bottom: 3em;

        span {
          font-family: "Open Sans",sans-serif;
          font-size: .9rem;
          color: #3d3d3d;
          font-weight: 600;
        }
      }

    }
  }

  table tr td div.checkbox {
    label {
      padding-left: 15px;
    }

    span.checkmark {
      background: #f2f2f2;
      top: -5px;
    }
  }

  textarea {
    @include linearGradient(#e8e8e8, #fcfcfc);

  }

}