
/** ROLLCALL **/

body.rollcall {

  table#results {

    th {
      font-weight: bold;
    }

    td {
      font-weight: normal;

      .specialinfo {

        display:block;
        margin-top: 1rem;

      }

    }

  }
}

/** COORDINATOR SIDEBATR **/

.asidetoggle{

  position:absolute;
  right:43px;
  top:21px;

  button.hamburger{
    span.name {
      font-weight: normal;
    }
  }
}

.coordinator-sidebar {

  background-color: #F7F1E7;
  border-radius: 7px;
  padding: 1rem;
  .header {
    h3 {
      font-size: 1.15rem;
      font-weight: 900;
      font-style: italic;
      line-height: 1.1;
      color: $navy;
      border-bottom: 2px solid $navy;
      padding-bottom: .4rem;
      margin-bottom: 1rem;
      letter-spacing: -1px;
    }
  }



  ul.fe-dashboard-nav {
    padding:0.25em;
    margin: 1em 0;
    list-style: none;

    li {
      color: $brand-grey;
      padding: 0.5em ;
      border-bottom: dotted 1px $brand-grey;
      &:last-child {
        border-bottom: 0px;
      }

      &:hover {
        background: $brand-orange;
        color: white;

        a {
          color: white;
        }

        span.fa {
          color: #000000;
        }

      }

      span.fa {
        font-size: 1.2em;
      }

      a {
        color: $brand-blue;
        &:hover {
          color: #ffffff;
        }
      }

    }


  }

  a.btn-admin {
    display: inline-block;
    background: #343434;
    color: #f7f7f7;;
    border-radius: 5px;
    font-family: $font-main;
    font-size: 1.2rem;
    font-weight: 900;
    padding: .8rem 1.2rem;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    margin: 10px 0 0 0;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.25);
    cursor: pointer;
    transform: translateZ(0);
    backface-visibility: hidden;
    transition: transform 0.3s $easing-pop 0.1s,
    -webkit-transform 0.3s $easing-pop 0.1s;
    &:hover, &:focus {
      color: #fff;
      transform: scale(1.058);
    }


  }


  ul.helplinks {
    list-style: none;
    padding: 0;
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    li {
      padding-bottom: 0.25em;
      margin-left: 0px;


      a {
        color: $brand-blue;
        &:hover {

        }
        &.active {
          color: $brand-orange;
        }
      }

    }

  }

}


/** COORDINATOR ANNOUNCEMENTS **/

#announcements-list{
  table {
    tr.unread {

    }

    tr.read {
      td {
        a {
          color: darken($brand-lightgrey, 30);
        }
      }
    }

  }

}

/** COORDINATOR BULK UPLOAD **/
body.coordinator {
  form.bulkuploadform {
    input#uplfile {
      padding: 0px;
      border-radius: 4px;
      button {
        border-radius: 4px 0 0 4px;
      }
    }
  }

  form input[type=text] {
    height: 2.4375rem;
    padding: 8px;
  }
}

/** Email Parents Form & preview ***/
body.coordinator
{
  form.emailcustomersform {

  }

  div#msg_preview {
    padding: 1.5rem;
    max-width: 650px;
    box-shadow: #33333388 0 0 8px;
    margin-bottom: 5rem;
    &.empty {
      box-shadow: none;
      margin-bottom: 1rem;
    }
  }
}