// main: ../main.scss

.btn-orange {
  display: inline-block;
  font-family: $font-main;
  font-size: 1.25rem;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
  color: #fff;
  border-radius: 5px;
  padding: 1rem 1.2rem;
  background: rgb(216,92,0);
  background: linear-gradient(0deg, rgba(216,92,0,1) 0%, rgba(255,134,44,1) 100%);
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.25);
  cursor: pointer;
  transform: translateZ(0);
  backface-visibility: hidden;
  transition: transform 0.3s $easing-pop 0.1s,
  -webkit-transform 0.3s $easing-pop 0.1s;
  &--large {
    font-size: 1.5rem;
    padding: 1rem 1.3rem;
  }
  &--nav {
    font-size: 1.5rem;
    padding: 1rem 1.3rem;
  }
  &:hover, &:focus {
    color: #fff;
    transform: scale(1.048);
  }
}

.play-button {
  position: relative;
  display: inline-block;
  max-width: 380px;
  font-size: 2.2rem;
  font-weight: 700;
  font-style: italic;
  line-height: 1;
  color: #fff;
  padding-left: 110px;
  padding-top: .6rem;
  text-align: left;
  text-shadow: 0px 0px 9px rgba(#000,.5);
  transition: .4s $easing-pop .1s;
  .play-icon {
    position: absolute;
    top: 0; left: 0;
    display: inline-block;
    height: 90px;
    width: 90px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.2);
    &:after {
      content: '';
      position: absolute;
      top: 1.72rem;
      left: 2.15rem;
      width: 0;
      height: 0;
      border-top: 18px solid transparent;
      border-left: 34px solid $orange;
      border-bottom: 18px solid transparent;
    }
  }
  &:hover, &:focus {
    color: #fff;
    transform: scale(1.058);
  }
}

.btn-navy {
  display: inline-block;
  font-family: $font-main;
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
  color: #fff;
  border-radius: 5px;
  padding: .8rem 1.2rem;
  background-color: $navy;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.25);
  cursor: pointer;
  transform: translateZ(0);
  backface-visibility: hidden;
  transition: transform 0.3s $easing-pop 0.1s,
  -webkit-transform 0.3s $easing-pop 0.1s;
  &:hover, &:focus {
    color: #fff;
    transform: scale(1.058);
  }
}

.btn-grey {
  display: inline-block;
  font-family: $font-main;
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
  color: #848484;
  border-radius: 5px;
  padding: .8rem 1.2rem;
  background-color: #dedede;
  cursor: pointer;
  transform: translateZ(0);
  backface-visibility: hidden;
  transition: transform 0.3s $easing-pop 0.1s,
  -webkit-transform 0.3s $easing-pop 0.1s;
  &:hover, &:focus {
    color: #848484;
    transform: scale(1.058);
  }
}

/* Info link */
.info-link {
	display: inline-block;
	color: #555555;
	font-weight: 700;
	font-size: .95rem;
	margin-bottom: .5rem;
	transition: .3s;
	&:after {
		content: "\ea0c";
		font-family: "icomoon";
		font-weight: normal;
		margin-left: .5rem;
		font-size: 1.7rem;
		vertical-align: sub;
		transition: .3s;
	}
	&:hover, &:focus { color: $navy;
		&:after { color: $orange; }
	}
  &.pull-up { margin-top: -2rem; }
}

.download-link {
  position: relative;
  display: inline-block;
  color: $navy;
  font-size: 1.2rem;
  font-weight: 800;
  font-style: italic;
  padding-left: 1.9rem;
  margin-bottom: 1rem;
  border-bottom: 1px dashed transparent;
  transition: .3s ease-out;
  &:before {
    content: "\f019";
    font-family: "icomoon";
    position: absolute;
    top: 0;
    left: 0;
    font-weight: normal;
    font-style: normal;
    transition: .25s ease-out;
  }
  &:hover {
    color: $navy;
    border-color: $navy;
    &:before { color: $orange; }
  }
}
