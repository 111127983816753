// main: ../main.scss

.card-feature {
  margin: .5rem 0;
  .content {
    background: rgb(235,235,235);
    background: linear-gradient(0deg, rgba(235,235,235,1) 0%, rgba(255,255,255,1) 70%);
    padding: 1.5rem 1.7rem;
    border-radius: 10px;
    border-bottom-left-radius: 0;
  }
  .title {
    // position: relative;
    font-size: 1.8rem;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.1;
    color: #000;
    // padding-left: 42px;
    margin-bottom: 1rem;
    // min-height: 34px;
    // &:before {
    //   content: '';
    //   width: 32px;
    //   height: 34px;
    //   text-align: center;
    //   background-image: url('../img/layout/logo-icon.png');
    //   background-position: center;
    //   background-size: contain;
    //   background-repeat: no-repeat;
    //   position: absolute;
    //   top: 0; left: 0;
    // }
  }
  .article-title {
    display: block;
    color: $navy;
    font-size: 2.1rem;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 1rem;
    transition: .3s;
    &:hover { color: $navy-dark; }
  }
  p {
    font-size: 1.15rem;
    font-weight: 500;
    line-height: 1.4;
    color: #818181;
    margin-bottom: 1.5rem;
  }
  small {
    font-size: 1rem;
    color: #818181;
    a {
      color: $navy-dark;
      text-transform: uppercase;
      font-weight: 700;
      transition: .2s;
      &:hover { opacity: .8; }
    }
  }
  hr {
    margin: 1.65rem auto 1.25rem;
  }
  .btn-more {
    position: relative;
    display: inline-block;
    background-color: #EBEBEB;
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    color: $navy;
    padding: .3rem 2.5rem .8rem 1.7rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: .25s ease-out;
    &:after {
      content: '';
      position: absolute;
      top: .62rem; right: 1.3rem;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-left: 8px solid $orange;
      border-bottom: 6px solid transparent;
      transition: .25s ease-out;
    }
    &:hover { color: $navy-dark;
      &:after { transform: translateX(4px); }
    }
  }
  &--red {
    .content { background: $red; }
    .btn-more {
      background-color: $red;
      color: #fff;
      &:after { border-left-color: #fff; }
      &:hover { color: #fff; }
    }
  }
  &--outline {
    .content { border: 1px solid #707070; }
    .btn-more {
      margin-top: -1px;
      border-left: 1px solid #707070;
      border-right: 1px solid #707070;
      border-bottom: 1px solid #707070;
    }
  }
}
