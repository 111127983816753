body.error {


  .yellow-card {
    background-color: #ffcc00;
    height:1.8rem;
    width:1rem;
    border: solid 1px #f5b80a;
    display: inline-block;
  }


  div.large-9 {

    div.content-wrapper {
      min-height: 560px;
      padding-left: 2rem;
      padding-right: 2rem;
    }

  }

}