// main: ../main.scss

/* Fonts */
$font-main: 'Montserrat', sans-serif;

/* Easings */
$easing-pop: cubic-bezier(0.09, 0.655, 0.305, 1.65);

/* Colours */
$navy: #2E536C;
$navy-dark: #0B3450;
$orange: #E96F14;
$red: #9e0a1f;
$yellow: #FFD504 ;
$navybase: #03475C ;
$text-grey: #5C5C5C;

/* Legacy 'Brand' Colours */
$brand-grey: #343434;
$brand-blue: #0c4a5e;
$brand-orange: #f4802f;
$brand-lightgrey: #dbdbdb;
$brand-yellow: #fcc43e;
$brand-green: #376a21;

/* Legacy Fonts */
$font-title: "Mouse Memoirs",sans-serif;
$font-btn: 'Wendy One', sans-serif;

$font-smallest: 0.625rem ; // 10px
$font-mediumsmall: 0.750rem;  // 12px
$font-small: 0.875rem;  // 14px

/*icomoon font-face icons*/
@font-face { font-family: "icomoon"; src: url("../../icons/icomoon.eot?dlzl4g"); src: url("../../icons/icomoon.eot?dlzl4g#iefix") format("embedded-opentype"), url("../../icons/icomoon.ttf?dlzl4g") format("truetype"), url("../../icons/icomoon.woff?dlzl4g") format("woff"), url("../../icons/icomoon.svg?dlzl4g#icomoon") format("svg"); font-weight: normal; font-style: normal; }

[class^="sz-icon-"], [class*=" sz-icon-"] { /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.sz-icon-file-empty:before {
  content: "\e924";
}
.sz-icon-info:before {
  content: "\ea0c";
}
.sz-icon-facebook:before {
  content: "\ea90";
}
.sz-icon-instagram:before {
  content: "\ea92";
}
.sz-icon-twitter:before {
  content: "\ea96";
}
.sz-icon-youtube:before {
  content: "\ea9d";
}
.sz-icon-file-pdf:before {
  content: "\eadf";
}
.sz-icon-file-word:before {
  content: "\eae1";
}

.sz-icon-pencil:before {
  content: "\e905";
}

.sz-icon-download:before {
  content: "\f019";
}

@mixin fader { transition: opacity 0.3s; opacity: 1;
	&:hover { opacity: 0.8; }
}

// .example-link {
//   @include fader;
// }

// Legacy MIXINS

@mixin linearGradient($top, $bottom){
  background-color: $bottom; /* Old browsers */
  background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

@mixin borderRadius($size){
  -webkit-border-radius: $size;
  -moz-border-radius: $size;
  border-radius: $size;
}


@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

/// Creates a CSS triangle, which can be used for dropdown arrows, dropdown pips, and more. Use this mixin inside a `&::before` or `&::after` selector, to attach the triangle to an existing element.
///
/// @param {Number} $triangle-size - Width of the triangle.
/// @param {Color} $triangle-color - Color of the triangle.
/// @param {Keyword} $triangle-direction - Direction the triangle points. Can be `up`, `right`, `down`, or `left`.
@mixin css-triangle(
  $triangle-size,
  $triangle-color,
  $triangle-direction
) {
  display: block;
  width: 0;
  height: 0;

  border: inset $triangle-size;

  content: '';

  @if ($triangle-direction == down) {
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: $triangle-color transparent transparent;
  }
  @if ($triangle-direction == up) {
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent $triangle-color;
  }
  @if ($triangle-direction == right) {
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent $triangle-color;
  }
  @if ($triangle-direction == left) {
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent $triangle-color transparent transparent;
  }
}