// main: ../main.scss

/* wysiwyg area */
.wysiwyg { color: $text-grey;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: .5rem;
		font-weight: 900;
		font-style: italic;
		text-align: left;
		letter-spacing: -2px;
		line-height: 1;
		color: $navy;
	}
	h1 { font-size: 3.4rem; }
	h2 { font-size: 2.2rem; }
	h3 { font-size: 2rem; }
	h4 { font-size: 1.8rem; }
	h5 { font-size: 1.6rem; }
	h6 { font-size: 1.45rem; }
	p {
		font-size: 1.1rem;
		margin-bottom: 1.5rem;
		font-weight: 500;
		&.lead {
			font-size: 1.3rem;
			font-weight: 600;
			letter-spacing: -1px;
		}
	}
	a {
		border-bottom: 1px dashed $navy;
		transition: .3s ease-out;
		&:hover {
			color: $navy;
			 border-color: $orange;
		 }
	}
	ol,
	ul {
		background-color: #e7f4f7;
		border-radius: 7px;
		padding: 1.2rem 1rem .7rem 1.5rem;
		margin-bottom: 1.8rem;
		margin-left: 0;
		position: relative;
		li { font-size: 1.1rem; }
	}
	ul {
		//list-style: none;

		//li {
		//	position: relative;
		//	padding: 0 0 .8rem 1rem;
		//
		//	&:before {
		//		content: '';
		//		height: 8px;
		//		width: 8px;
		//		background-color: $navy;
		//		border-radius: 50%;
		//		position: absolute;
		//		top: 10px;
		//		left: 0;
		//	}
		//}
	}

	ol {
		/*list-style: none;*/
		/*counter-reset: list-counter;*/
		li {
			position: relative;
			padding-bottom: .8rem;
			counter-increment: list-counter;
			margin-left: 1rem;
			&:before {
				/*content: counter(list-counter) ". ";*/
				color: $navy;
				font-size: 1.2rem;
				font-weight: 800;
				font-style: italic;
			}
		}
	}
	img {
		width: 100%;
		margin-bottom: 1.8rem;
		&.right { float: right; width: 35%; margin-left: 1.8rem; }
		&.left { float: left; width: 35%; margin-right: 1.8rem; }
	}
	img[style*="float: left"] { margin: 1rem 1.5rem 1rem 0; width: 300px; }
	img[style*="float: right"] { margin: 1rem 0 1.5rem 1rem; width: 300px; }

	hr { margin: 1.5rem auto 1.5rem auto; border-color: #e4e4e4; }
	table {
		width: 100% !important;
		position: relative;
		th {
			font-size: 1.1rem;
			color: $navy;
			font-weight: 800;
			font-style: italic;
		}
		td {
			color: #363636;
			font-size: 1.1rem;
			vertical-align: top;
			font-weight: 500;
		 }
		thead {
			background-color: #e7f4f7;
			border: 0;
			border-radius: 5px;
		}
		tbody {
			border: 0;
			tr { border-bottom: 1px dashed #b6b6b6; }
			tr:nth-child(even) { background-color: transparent; }
		}
	}
	.fluid-width-video-wrapper { box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); border: none; margin-bottom: 2em; }
}

/*general forms*/
.form-general { margin-bottom: 1em;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 { margin-bottom: 1rem; font-weight: 600; text-align: left; color: #363636; }
	h1 { font-size: 1.8625rem; }
	h2 { font-size: 1.8625rem; }
	h3 { font-size: 1.7625rem; }
	h4 { font-size: 1.3625rem; }
	h5 { font-size: 1.2625rem; }
	h6 { font-size: 1rem; }
	hr { margin: 1.5rem auto 1.5rem auto; border-color: #e4e4e4; }
	li { margin-bottom: 0.6em; }
	label,
	legend {
		display: inline-block;
		font-weight: 800;
		font-style: italic;
		font-size: 1.3rem;
		color: $navy;
		letter-spacing: -1px;
		line-height: 1.2;
		margin-bottom: .5rem;
	}
	input,
	select {
		height: 48px;
		border: 2px solid #AFAFAF;
		border-radius: 7px;
		color: #5C5C5C;
		font-size: 1.2rem;
		font-weight: 700;
		font-style: italic;
		padding-left: 1rem;
		margin-bottom: 1.2rem;
		&:focus {
			border: 2px solid $navy;
		}
	}
	select {
		background-image: url('../../img/layout/arrow-down.svg');
		background-size: 18px;
		padding-right: 1.5rem;
	}
	textarea {
		border: 2px solid #AFAFAF;
		border-radius: 7px;
		color: #5C5C5C;
		font-size: 1.2rem;
		font-weight: 700;
		font-style: italic;
		padding-left: 1rem;
		margin-bottom: 1.2rem;
	 }
	fieldset {
		label { display: inline-block; font-weight: 400; font-size: 0.86em; }
	}
	.error-text { text-transform: lowercase; font-weight: 400; display: block; padding: 1em; margin: 0 0 0.8em 0; border-right: 2px dotted #91071a; border-left: 2px dotted #91071a; color: #91071a; background-color: lighten(#91071a, 50%); line-height: 1; }
	.cmd { font-weight: 600; text-transform: uppercase; background-color: #3d3d3d; color: #fff; transition: all 0.5s; }
}

.msg-sent-ok { display: block; text-transform: lowercase; font-weight: 400; padding: 1em; margin: 0 0 0.8em 0; border-right: 2px dotted green;  border-left: 2px dotted green; color: #363636; background-color: #D9F2F4; line-height: 1; }
.msg-validation-error { display: block; text-transform: lowercase; font-weight: 400; padding: 1em; margin: 0 0 0.8em 0; border-right: 2px dotted #91071a;  border-left: 2px dotted #91071a; color: #91071a; background-color: lighten(#91071a, 50%); line-height: 1; }
