// main: ../main.scss

/* Wrappers */
.wrapper-main-bg {
	position: relative;
	background-color: #fff;
	padding: 1rem 0 4rem;
	min-height: 750px;

	.bg-img {
		position: absolute;
		top: 0;
		width: 100%;
		height: 730px;
		background-image: url('../../img/layout/grass-bg.jpg');

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 270px;
			background: rgb(255,255,255);
			background: linear-gradient(0deg, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 100%);
		}
	}

	.grid-container {
		position: relative;
	}
}

.wrapper-info {
	background-color: $navy-dark;
	background-image: -webkit-linear-gradient(0deg, $navy 20%, #0B3450 20%);

	.cell {
		padding-top: 1rem;
		padding-bottom: .5rem;
	}

	.diagonal {
		position: relative;

		&:before {
			content: '';
			height: 100%;
			width: 100%;
			background-color: $navy;
			position: absolute;
			top: 0;
			right: 1rem;
			transform: skewX(-35deg);
		}
	}

	.title {
		position: relative;
		font-size: 1.8rem;
		color: #fff;
		font-weight: 900;
		font-style: italic;
		line-height: 1.2;
	}

	p {
		color: #fff;
		margin-bottom: .4rem;
		font-weight: 500;
		font-style: italic;
		font-size: 1.05rem;
		line-height: 1.4;
	}
}

.wrapper-coaches {
	background-color: #ffcf32;
	background-image: -webkit-linear-gradient(0deg, #ffcf32 20%, $brand-yellow 20%);
	.cell {
		padding-top: 1rem;
		padding-bottom: .5rem;
	}

	.twist {
		transform: rotatez(-20deg);
	}
	.diagonal {
		position: relative;
		&:before {
			content: '';
			height: 100%; width: 100%;
			background-color: #ffcf32;
			position: absolute;
			top: 0; right: 1.4rem;
			transform: skewX(35deg);
		}
	}
	.title {
		position: relative;
		font-size: 1.8rem;
		color: $navy;
		font-weight: 900;
		font-style: italic;
		line-height: 1.7;
	}
	p {
		color: $navy;
		margin-bottom: .4rem;
		font-weight: 500;
		font-style: italic;
		font-size: 1.05rem;
		line-height: 1.4;
	}
}


/* Containers */
.main-container {
	position: relative;
	background-color: #fff;
	margin: 2rem 0 3.5rem;
	padding: 4rem 2.5rem 2.5rem;
	border-radius: 30px 30px 10px 10px;
	box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.15);

	&:before {
		content: '';
		height: 10px;
		position: absolute;
		top: .6rem;
		left: 1.5rem;
		right: 1.5rem;
		border-radius: 10px;
		background: rgb(255,255,255);
		background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(190,190,190,0.25) 100%);
	}
}

/* Dropdown */
.dropdown {
	background-color: #EFEFEF;
	border: 1px solid #707070;
	border-radius: 7px;
	color: $navy;
	font-size: 1.1rem;
	font-weight: 700;
	height: 48px;
	box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
	margin-bottom: 1.25rem;
	background-image: url('../../img/layout/caret-down.png');
	background-size: 10px;
	padding-right: 1.8rem;
	padding-left: 1rem;
}

/* Kellogg's boxes display */
.boxes-display {
	margin-right: .5rem;
	margin-left: -2rem;

	img {
		max-height: 248px;
	}

	.cell {
		text-align: center;
	}
}

/* Competition Steps */
.steps-header {
	border-bottom: 1px solid #DEDEDE;
	margin-bottom: 2rem;

	&--booking {
		margin-bottom: 3.5rem;
	}
}

.steps-list {
	display: flex;
	list-style: none;
	margin: 0 0 -1px 0;

	li {
		display: inline-block;
		max-width: 155px;
		margin-left: 1rem;

		&:first-child {
			margin-left: 0;
		}
	}

	a {
		display: block;
		color: #848484;
		font-size: 1.25rem;
		font-weight: 800;
		font-style: italic;
		letter-spacing: -1px;
		text-align: center;
		line-height: 1;
		background-color: #F5F5F5;
		padding: 1rem .5rem;
		border: 1px solid #DEDEDE;
		border-radius: 10px 10px 0 0;

		&.active {
			background-color: #fff;
			color: $red;
			border-bottom-color: #fff;
		}

		&.disabled {
			cursor: default;
		}
	}
}

.step-content {
	padding: 0 1.5rem;

	label, p {
		color: $navy;
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 1.4;
		margin-bottom: 1.5rem;
	}

	.txt-title {
		margin-bottom: .5rem;
		font-size: 1.8rem;
		font-weight: 900;
	}

	input, select, textarea {
		color: #000;
		font-weight: 800;
		font-style: italic;
		font-size: 1.4rem;
		border: 2px solid #AFAFAF;
		border-radius: 7px;
		padding-left: 1.2rem;
		margin-bottom: 1.8rem;

		&:focus {
			border: 2px solid #AFAFAF;
		}

		&::placeholder {
			color: #848484;
		}
	}

	input {
		height: 62px;
	}

	select {
		color: #848484;
		height: 62px;
		background-image: url('../../img/layout/arrow-down.svg');
		background-size: 30px;
		padding-right: 2rem;
	}

	.title {
		color: $navy;
		font-weight: 800;
		font-size: 1.5rem;
		font-style: italic;
		margin-bottom: .4rem;

		&--large {
			font-size: 2rem;
			letter-spacing: -2px;
		}
	}

	.cta {
		display: inline-block;
		margin-bottom: 1rem;
		background-color: #02475B;
		border: 4px solid #fff;
		color: #fff;
		font-size: 1.9rem;
		font-weight: 900;
		text-transform: uppercase;
		border-radius: 5px;
		padding: .85rem 1.5rem .8rem;
		cursor: pointer;
		line-height: 1;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
		transition: background-color .3s ease-out;

		&:hover, &:focus {
			background-color: #fff;
			color: #02475B;
		}

		&--orange {
			background-color: $orange;
		}

		&--expanded {
			width: 100%;
		}
	}
}

.competition-container {
	background-color: #f2f2f2;
	border-radius: 10px;

	&--highlight {
		background-color: $yellow;
	}

	.padder {
		padding: 2rem;
	}

	.base-wrapper {
		background-color: $navybase;
		border-radius:10px;
		padding: 1rem 2rem;
	}
}


/* Booking elements */
.booking-steps {
	list-style: none;
	display: flex;
	list-style: none;
	margin: 0 0 -1px 0;
	justify-content: flex-end;

	li {
		display: inline-block;
		min-width: 90px;
		margin-left: .8rem;
		color: #848484;
		font-size: 1.25rem;
		font-weight: 800;
		font-style: italic;
		letter-spacing: -1px;
		text-align: center;
		line-height: 1.1;
		background-color: #F5F5F5;
		padding: .7rem .5rem .5rem;
		border: 1px solid #DEDEDE;
		border-radius: 10px 10px 0 0;

		span {
			display: block;
			font-size: 2.1rem;
		}

		&:first-child {
			margin-left: 0;
		}

		&.active {
			background-color: #fff;
			border-bottom-color: #fff;
		}
	}
}

.booking-container {
	.side-sep {
		border-right: 1px dashed #b6b6b6;
		padding-right: 2rem;
	}

	.sep {
		border-bottom: 1px dashed #b6b6b6;
		margin: .5rem auto 2rem;
	}

	p {
		color: #5C5C5C;

		strong {
			font-weight: 800;
		}

		em {
			font-weight: 600;
		}
	}
}

.booking-title {
	font-size: 2rem;
	font-weight: 500;
	color: #848484;
	letter-spacing: -1px;
	line-height: 1.2;
	margin-bottom: 2rem;
}

.form-header {
	border-bottom: 3px solid $navy-dark;
	margin-bottom: 3rem;
	padding-bottom: .5rem;
	margin-top: 2rem;

	.heading {
		color: $navy-dark;
		font-size: 1.7rem;
		font-weight: 700;
		letter-spacing: -1px;
	}

	.req {
		display: inline-block;
		font-size: 1rem;
		margin-bottom: .25rem;
		color: $navy-dark;
		font-weight: 700;
	}
}

.booking-form {
	label, .fauxlabel {
		display: block;
		font-weight: 800;
		font-style: italic;
		font-size: 1.9rem;
		color: $navy;
		letter-spacing: -1px;
		padding: 1rem 1rem 1rem 1.3rem;
		line-height: 1.2;
		background-color: #e7f4f7;
		border-radius: 7px;
		margin-right: -7px;
		margin-bottom: 1.7rem;
		min-height: 68px;

		&.smaller-text {
			font-size: 1.7rem;
		}

		&.small-text {
			font-size: 1.42rem;
			line-height: 1;
			padding: .74rem 1rem .74rem 1.3rem;
		}
		span.lightweight-text {
			font-weight: 400;
			font-size: 1.1rem;
			display: inline-block;
			margin-top: -0.3rem;
		}
	}

	input, select, textarea {
		height: 68px;
		border: 2px solid #AFAFAF;
		border-radius: 7px;
		color: #5C5C5C;
		font-size: 1.4rem;
		font-weight: 700;
		font-style: italic;
		padding-left: 1.5rem;
		margin-bottom: 1.7rem;

		&:focus {
			border: 2px solid $navy;
		}
	}

	select {
		letter-spacing: -1px;
		background-image: url('../../img/layout/arrow-down.svg');
		background-size: 28px;
		padding-right: 2rem;
	}

	textarea {
		height: auto;
	}

	.help-block {
		display: block;
		font-size: .9rem;
		color: #848484;
		display: block;
		line-height: 1.4;
		margin-left: .2rem;
		margin-bottom: 1.5rem;
		margin-top: -1.4rem;
	}

	.form-link {
		display: table;
		margin-top: -1.1rem;
		margin-bottom: 1.5rem;
		margin-left: .25rem;
		font-size: .95rem;
		font-weight: 600;
		line-height: 1;
		padding-bottom: .2rem;
		border-bottom: 1px dashed transparent;
		transition: color .3s ease-out, border-color .3s ease-out;

		span {
			margin-right: .3rem;
			vertical-align: bottom;
			font-size: 1.1rem;
		}

		&:hover {
			color: $navy;
			border-color: $navy;
		}
	}

	.padder-left {
		padding-left: 1.8rem;
	}

	.sportlist {
		color: #5C5C5C;
		font-size: 1.4rem;
		font-weight: 700;
		font-style: italic;
		line-height: 1.2;
		margin-bottom: 1.8rem;
	}

	.alternate-camps {
		list-style: none;
		margin: 0 0 2rem 0;

		li {
			color: #5C5C5C;
			font-size: 1.4rem;
			font-weight: 700;
			font-style: italic;
			line-height: 1.2;
			margin-bottom: .8rem;
		}
	}
}

.booking-proceed {
	border-top: 3px solid $navy-dark;
	padding: 3rem 0 1rem;
	margin-top: 3rem;
}

.order-summary {
	background-color: #F7F1E7;
	border-radius: 7px;
	padding: 1rem;

	.header {
		font-size: 1.45rem;
		font-weight: 900;
		font-style: italic;
		color: $navy;
		border-bottom: 2px solid $navy;
		padding-bottom: .4rem;
		margin-bottom: 1rem;
		letter-spacing: -1px;
	}

	p {
		font-size: 1rem;
		color: #5C5C5C;
		margin-bottom: .5rem;
		line-height: 1.4;

		strong {
			font-weight: 700;
		}
	}

	.cell:first-child {
		padding-right: .8rem;
	}

	hr {
		border-bottom: 1px solid rgba($navy, .5);
		margin: .5rem auto 1rem;
	}

	.sub-heading {
		font-size: 1.15rem;
		font-weight: 800;
		font-style: italic;
		border-bottom: 1px solid rgba($navy, .7);
		color: $navy;
		padding-bottom: .2rem;
		margin-top: .3rem;
	}


    .sub-total,
	.total {
		padding: .5rem 1rem;
		border-radius: 5px;
		margin-top: 1rem;
		span, p {
			font-size: 1.1rem;
			margin-bottom: 0;
		}

		p {
			text-transform: uppercase;
		}
	}

	.sub-total {
		background-color: $brand-lightgrey;
		margin-bottom: -1.2rem;
		font-weight: 100;
		span, p {
			font-size: 0.9rem !important;
			color: #2b2b2b;
		}

	}

	.total {
		background-color: $navy;
		font-weight: 700;
		span, p {
			color: #fff;
		}
	}
}

.booking-steps-mobile {
	font-size: 1.3rem;
	font-weight: 800;
	font-style: italic;
	letter-spacing: -1px;
	line-height: 1;
	color: #848484;
}

.kit-size-list {
	list-style: none;
	margin: 0;

	li {
		display: inline-block;
		margin-right: .85rem;

		&:last-child {
			margin-right: 0;
		}
	}

	.radio-holder {
		position: relative;
		background-color: #fff;
		border: 2px solid #AFAFAF;
		margin-bottom: 1rem;
		border-radius: 7px;
		color: #5C5C5C;
		font-size: 1.4rem;
		font-weight: 700;
		font-style: italic;
		line-height: 1.2;
		padding: 2.5rem .5rem .65rem;
		text-align: center;
		min-width: 81px;

		&.out-of-stock {
			background-color: $brand-lightgrey;
			&:after {
				content: '';
				width: 63px;
				height: 65px;
				position: absolute;
				right: 0;
				top:0;
				background-image: url('../../img/layout/kit-sold-out.png');
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 0 6px 0 0;
			}
		}

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;

			&:checked ~ .checkmark {
				background-color: $navy;
				border-color: $navy;

				&:after {
					display: block;
				}
			}
		}

		.checkmark {
			position: absolute;
			top: 12px;
			left: 50%;
			transform: translateX(-50%);
			height: 22px;
			width: 22px;
			background-color: #fff;
			border: 1px solid #b5b5b5;
			border-radius: 50%;
			box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);

			&:after {
				content: "";
				position: absolute;
				display: none;
				top: 6px;
				left: 6px;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: #fff;
			}
		}

		.size {
			display: block;
		}

		.age {
			display: block;
			color: #848484;
			font-size: .9rem;
			font-weight: 600;
			font-style: normal;
		}
	}
}

/* Checkbox */
.checkbox-container {
	min-height: 0 !important;
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 1.4rem !important;
	line-height: 1.3;
	margin-bottom: 1.5rem !important;
	background-color: transparent !important;
	color: #5C5C5C !important;
	padding: .162rem 0 0 2.7rem !important;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;

		&:checked ~ .checkmark {
			background-color: $navy;
			border-color: $navy;

			&:after {
				display: block;
				border-color: #fff;
			}
		}
	}

	.checkmark {
		position: absolute;
		top: 1px;
		left: .2rem;
		height: 28px;
		width: 28px;
		background-color: #fff;
		border: 2px solid #b5b5b5;
		border-radius: 3px;
		box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);

		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 8px;
			top: 2px;
			width: 8px;
			height: 16px;
			border: solid #b5b5b5;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}

/* Info alert */
.info-alert {
	position: relative;
	background-color: #f5f5f5;
	padding: 1.2rem 1.2rem 1.2rem 4.8rem;
	border-radius: 7px;
	color: $text-grey;
	font-weight: 600;
	font-size: 1.05rem;
	line-height: 1.4;

	&--light {
		font-weight: 100;
	}

	&:before {
		content: "\ea0c";
		font-family: "icomoon";
		font-size: 2.7rem;
		color: $orange;
		position: absolute;
		top: 1.25rem;
		left: 1.2rem;
		line-height: 1;
		font-weight: normal;
	}
}

/* Edit form */
.form-edit {
	label {
		font-weight: 800;
		font-style: italic;
		font-size: 1.2rem;
		color: $navy;
		letter-spacing: -1px;
		line-height: 1.2;
		margin-bottom: .5rem;
		background-color: #E7F4F7;
		padding: .65rem 1rem;
		border-radius: 5px;

		span {
			font-weight: 600;
			font-size: .9rem;
		}
	}

	input, textarea {
		min-height: 42px;
		height: auto;
		border: 2px solid #AFAFAF;
		border-radius: 7px;
		color: #5C5C5C;
		font-size: 1.1rem;
		font-weight: 700;
		font-style: italic;
		padding-left: 1rem;
		margin-bottom: 1.7rem;

		&:focus {
			border: 2px solid $navy;
		}
	}
	textarea#address {
		min-height:7rem;
	}
	textarea.attendees {
		min-height:5rem;
	}

	&.read-only {
		input[disabled] {
			background: transparent;
			border: none;
			box-shadow: none;
			color: #5C5C5C;
			opacity: 1;
		}

		textarea[disabled] {
			background: transparent;
			border: none;
			box-shadow: none;
			color: #5C5C5C;
			opacity: 1;
			height: auto;
			line-height:1.4
		}
	}

	.edit-header {
		border-bottom: 2px solid $navy-dark;
		margin-bottom: 1rem;
		padding-bottom: .2rem;

		.heading {
			color: $navy-dark;
			font-size: 1.5rem;
			font-weight: 700;
			letter-spacing: -1px;
		}
	}

	.btn-edit {
		position: relative;
		display: inline-block;
		font-size: 1.1rem;
		color: $navy-dark;
		font-weight: 600;
		font-style: italic;
		margin-bottom: .7rem;
		padding-left: 1.5rem;
		cursor: pointer;

		&:before {
			content: "\e905";
			font-family: "icomoon";
			font-style: normal;
			font-weight: normal;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.btn-save {
		display: none;
		position: relative;
		font-size: 1.1rem;
		color: $navy-dark;
		font-weight: 600;
		font-style: italic;
		margin-bottom: .7rem;
		padding-left: 1.5rem;
		cursor: pointer;

		&:before {
			content: "\e905";
			font-family: "icomoon";
			font-style: normal;
			font-weight: normal;
			position: absolute;
			top: 0;
			left: 0;
			color: $orange;
		}
	}
}


.listing-items {
	margin: 0;
	list-style: none;

	li {
		border-bottom: 1px solid #f2f2f2;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
	}

	.img {
		display: block;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover !important;
		height: 140px;
	}

	.title {
		font-size: 1.4rem;
	}

	.meta {
		font-size: .8rem;
	}
}

.base-content {
	padding: 1rem 2rem 0 2rem;

	p {
		color: #fff;
		font-size: 1.1rem;
		font-weight: 500;
		line-height: 1.4;
	}

	a {
		color: #fff;
		border-bottom:1px dashed #fff; 
		@include fader;
	}
}

.step-callout {
	padding: 1.5rem 2rem .5rem 2rem;
	background-color: #fff;
	border-radius: 10px;
}