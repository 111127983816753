// WIZARD STEPS
// -------------------------------------------------------

div.offline-alert {

  background-color: $brand-yellow;
  color: #333333;
  width: 100%;
  margin-bottom: 2rem;

  a.button-exit {
    padding: 0.4rem;
  }

  p:last-child {
    margin-bottom: 0m;
  }

}

// General INPUT FIELDS

/*input[type=number],
input[type=email],
input[type=tel],
input[type=text],
textarea, input, select {
  color: $brand-grey;
  background-color: #fefefe;
  border: solid 2px $brand-lightgrey;
  @include borderRadius(7px);

  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  margin: 0 0 1rem;
  padding: .5rem;

  color: $text-grey;
  font-size: 1.4rem;
  font-weight: 700;
  font-style: italic;

  -webkit-box-shadow: inset 0 1px 2px rgba(10,10,10, 0.1);
  -moz-box-shadow: inset 0 1px 2px rgba(10,10,10, 0.1);
  box-shadow: inset 0 1px 2px rgba(10,10,10,.1);

  transition: box-shadow .5s,border-color .25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    background: #ffffff;
  }

  &.disabled, &:disabled {
    color: $brand-lightgrey;
    background: lighten($brand-lightgrey, 30);
    border-color: darken($brand-lightgrey, 10);
  }

}*/

select {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb(88, 88, 88)'></polygon></svg>");
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow .5s,border-color .25s ease-in-out;
}
// CHECKBOX OVER-RIDES
div.switch {

  label.switch-paddle {
    display: inline-block;
    position: relative;
    margin-bottom: inherit;
    cursor: pointer;
    font-size: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 1.6rem;

    background: #ee7070;

    .switch-inactive {
      color: #6e0404;
    }

    .switch-active {
      color: #0b3d02;
    }

    ~ .icon-inactive {
      display: inline-block;
      position: relative;
      top : -2rem;
      left: 1rem;
    }
    ~ .icon-active {
      display: none;
    }

  }

  input:checked ~ .switch-paddle {
    background: #b4e0b4 !important;
    ~ .icon-active {
        display:inline-block;
      position: relative;
      top : -2rem;
      left: 1rem;
    }
    ~ .icon-inactive {
      display:none;
    }

  }
}

label.radiocontainer,
label.checkboxcontainer {
  display: block;
  position: relative;
  &.checkboxcontainer {
    padding-left: 35px;
  }
  &.radiocontainer{
    padding-top: 20px;
    text-align: center;
    small {
      font-weight: normal;
      font-size:0.7em;
      color: lighten($brand-grey, 50);
    }
  }
  margin-bottom: inherit;
  cursor: pointer;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // hide the default browser control
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top:-3px;
    z-index: 1;
    margin-left: -35px;
  }
  &.radiocontainer input {
    margin: 0 auto;
  }

  input:checked ~ .checkmark {
    background-color: $brand-lightgrey;
  }

  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 23px;
    width: 24px;
    background-color: $brand-lightgrey;
    border: solid 1px $brand-grey;
    @include borderRadius(4px);
    display: inline-block;

    &:after {
      content: "";
      position: absolute;
      display: none;

      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid $brand-grey;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);

    }
  }

  &.radiocontainer .checkmark  {
    margin-left: 0px;
    left:3px;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  &:hover input ~ .checkmark {
    background-color: darken($brand-lightgrey, 5);
  }

}

// Step 0 - Search Filters
.search-panel {
  .search-container {
    select {
      max-width: 90%;
    }

  }

  .search-date {
    select {
      max-width: 87%;
    }
  }
}

// Step 0 - Camp Locations & Dates

body.findcamps {

  // Make county cheaer address deatils smaller
  div.coordinator {
    span.txt-small {
      font-size: smaller;
    }
  }

  // make tabular data smaller font on mobile
  @media only screen and (max-width: 40em) {
     table {
       font-size: 0.688em; // 11px
     }

    div.coordinator {
      span.txt-small {
        font-size: 1em;
      }
    }

  }

}

body.stepsclosed {

  form.priority-access {
    background-color: $brand-yellow ;
    color: $navybase;
    border-radius: 10px;

    div.padder {
      padding: 2rem;
    }

    div.form-footer {
      background-color: $navybase;
      color: #fefefe;
      border-radius: 10px;
      padding: 1rem 2rem;

      .border-white{
        border: solid 3px #fefefe;
      }

    }

  }

}

// Step 1 - Parent Details

// Step 2 - Add Children
body.steptwo {
  div.info-alert {
    width: 100%;
    //margin-bottom:10px;
  }

  .advice-block {
    background: lighten($brand-yellow,20%);
    color: #222222;
    font-size: smaller;
    padding: 5px 10px;
  }

}

.popup {
  width:100%;
  height:100%;
  display:none;
  position:fixed;
  top:0;
  left:0;
  background:rgba(0,0,0,0.65);
  z-index:999;

  .popup-inner {
    max-width:700px;
    width:90%;
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform:translate(-50%, -50%);
    transform:translate(-50%, -50%);
    box-shadow:0px 2px 6px #000;
    background:#fff;

    .popup-body {
      padding:20px
    }

    .header {
      height:60px;
      background-color:#dbdbdb;
      color:#0c4a5e;
      padding:15px 25px;

      h4 {
        display:inline
      }

      .close {
        font-size:1.45em;
        float:right;
        color:#343434
      }

    }
  }

}

.btn-add-another {
  display: block;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  font-size: .8em;
  font-weight: bold;
  @include linearGradient(#6CA4CB, #3B6989);
  &:hover {
    color: #f2f2f2;
  }
}
// Step 3  - Add Camps

body.stepthree {


    .campSelector {

      label {
        background: #f7f7f7;
        border: solid 2px #444;
        margin: 0 1rem 1rem 1rem;
        font-size: 1.2rem;

        span {
          color: inherit
        }

        .campname {
          font-size: 1.4rem;
          font-weight: bold;
        }
      }

      label.selected {
        background: #aae8bf;
        border-width: 4px;
      }

      label.nocapacity {
        background: $brand-orange;
        color: #ffffff;

        div.small-10:after {
            font-weight: bold;
            font-size: 1.2em;
            content: 'No Capacity';
          }
      }

      .radio-holder {
        position: relative;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;

          &:checked ~ .checkmark {
            background-color: $navy;
            border-color: $navy;

            &:after {
              display: block;
            }
          }
        }

        .checkmark {
          display: inline-block;
          position: relative;
          top: 50%;
          left: 0px;
          transform: translate(-50%, -50%);
          height: 22px;
          width: 22px;
          background-color: #fff;
          border: 1px solid #b5b5b5;
          border-radius: 50%;
          box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);

          &:after {
            content: "";
            position: absolute;
            display: none;
            top: 6px;
            left: 6px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #fff;
          }
        }


        &:checked ~ .checkmark {
          background-color: $navy;
          border-color: $navy;

          &:after {
            display: block;
          }
        }
      }

    }

  .delete_camp_link {
    background: $brand-orange;
    color: #ffffff;
    padding: 0.3em;
  }

  .deleting {
    background: lighten($brand-orange, 50);
  }

  select.campSelector {
     &.nocapacity{
       background: $brand-orange;
       color: #ffffff;
     }
  }

  form span.fauxlabel, span.fauxlabel {
    font-weight: bold;
    color: #55585b;
    line-height: 1.1;
    font-size: 1em;
    font-family: "Open Sans", Sans-Serif;
  }

  div.sportlist {
    color: #55585b;
    font-size: 1em;
    padding-left :12px;
    line-height: 1.1;
  }

  div.map {
    width:100%;
    min-height:320px;
    box-shadow: rgba(143,143,143,0.8) 0px 0px 3px;
  }

  ul.alternate-camps {
    list-style: none;
    max-height:340px;
    overflow-y: scroll;
    background-color: #f0f0f0;
    padding: 20px;
    border: solid 2px #444444;
    border-radius: 7px;

    li {
      border-bottom: 1px solid #d8d8d8;
      padding: 5px 0 5px 0;
      overflow: hidden;

      span.details {
        font-weight:normal;
        color: #999999;
      }

    }

  }


}

// STEP 4 - Booking Summary

.secondary-text {
  color: darken($brand-lightgrey, 20);
}

body.stepfour {

  section{
    display: block;
    margin-bottom: 40px;
  }

  div.section-header{
    border-bottom: solid $brand-lightgrey 2px;
    h2 {
      margin-bottom: 0px;
    }
    margin-bottom:15px;
  }

  section#customer-details {
  }
  section#booking-camps {
  }
  section#booking-children {
  }

  .item-row,
  .camp-row {
    display: block;
    clear: both;
    margin-bottom: 20px;
  }

  // List of camp for each child
  .camplist {
    margin-top: 20px;
  }


}

a.btn-green{
  &.large {
    padding: 15px;
    margin-top: 20px;
  }
}

.order-summary {
  margin-bottom:20px;

  .header {
    background-color:#fff;
    color:#0c4a5e;
    margin-bottom:5px;
    padding:5px 10px 3px 10px ;
    p {
      color:#0c4a5e;
      text-transform:uppercase;
      font-weight:bold
    }
  }

  .summary {
    background-color:#fff;
    margin-bottom:5px;
    padding:5px 10px;
    color:#7b7b7b;
    //p {
    //  color:#7b7b7b;
    //  font-size:.75em;
    //  margin-bottom:0
    //}
  }

  //.total {
  //  padding:5px 10px 3px 10px;
  //  background-color:#f4802f;
  //  color: #ffffff;
  //}

}
.simple-table {
  width:auto;
  text-align:left;
  background-color:#fff;

  th, td {
    padding:5px;
  }

  th span {
    font-size:.85em;
    color:#55585b
  }
}


// STEP 5 - PAYMENT PAGE
body.stepfive {

  div.gdpr{
    background-color: #e7f4f7;
    border-radius: 7px;
    padding: 1.2rem 1rem .7rem 1.5rem;
    margin-bottom: 1.8rem;
    margin-left: 0;
    position: relative;
  }


  select.selectDate {
    max-width:5em !important;
  }

  .csv-input {
    max-width: 4em !important;
  }



  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
    font-weight:bold;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
    font-weight:normal;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
  }


}



.duplication-warning{
  background: #b92c28;
  border-color: #3b0e0d;
  color: #ffffff;
  margin: 0 0 1rem 0;
  padding: 1rem;
}



// STEP SIX - CONFIRMATION
body.stepsix {

  p.rxnumber {
    background: $brand-orange;
    padding: 1em 3em;
    color: #0e0e0e;
    border: solid 2px #ffffff;
    display: inline-block;
    font-weight: bold;
    font-size: 2em;
  }

}