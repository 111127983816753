// main: ../main.scss

html {
	&.access-size-small { font-size: 100% !important; }
	&.access-size-medium { font-size: 110% !important; }
	&.access-size-large { font-size: 120% !important; }
	&.access-colour-greyscale { -moz-filter: grayscale(100%); -webkit-filter: grayscale(100%); filter: grayscale(100%); }
	&.access-colour-contrast { /*set in the _accessibility.scss*/ }
}

/* Accessibility Settings */
.btn-txt-size { cursor: pointer; line-height: 1; border: 2px solid #cacaca; height: 28px; width: 28px; text-align: center; margin-right: 5px; vertical-align: middle;
	&.txt-small { font-size: .8rem; }
	&.txt-medium { font-size: 1rem; }
	&.txt-large { font-size: 1.25rem; }
	&.active { background-color: #177bb8; color: #fff; border-color: #177bb8; }
}

/* Base styles */
.oflow { overflow-y: hidden; }
.pos-rel { position: relative; }

/* Sticky  container */
.is-sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 1rem;
}

/* Typography */
h1, h2, h3, h4, h5, h6,
p,
div,
li,
span,
td,
label,
input,
select,
textarea,
cite,
small,
button,
a { font-family: $font-main; }

a { color: $navy; }

.title-main {
	font-size: 4rem;
	font-weight: 900;
	font-style: italic;
	letter-spacing: -3px;
	color: #294A61;
	line-height: 1;
	margin-bottom: 1.5rem;
}

.modal-title {
	font-weight: 800;
	font-style: italic;
	font-size: 1.5rem;
	color: $navy;
}
.modal-title--inverse {
	font-weight: 800;
	font-style: italic;
	font-size: 1.5rem;
	color: $brand-orange;
}

/* Nav */
.logo-container {
	position: relative;
	z-index: 3;
	text-align: center;
	margin-bottom: -240px;
	.logo {
		position: relative;
		display: inline-block;
	}
	.year {
		position: absolute;
		bottom: 38px; left: 50%;
		transform: translateX(-50%);
		color: $orange;
		font-weight: 700;
		font-size: 1.1rem;
		text-shadow: 0px 0px 4px rgba(#000,.5);
	}
}

.logged-in-user-header {
	margin-right:15px;

	.nav-identity {
		list-style: none;
		margin: 44px 0 0px 0;
		padding: 0 1rem 0 1.5rem;
		background: #F2804e;
		background: linear-gradient(0deg, #d85c00 0%, #ff862c 100%);
		color: #19395c;
		border-radius: 12px 12px 0 0 ;
		z-index: 0;

		li {
			display: inline;
			border: none;
			font-size: 0.9em;
			padding: 0;

			a {
				color: #fff;
				transition: .3s ease;

				&:hover {
					text-decoration: underline;
				}
			}

			&.text-white {
				color: #ffffff;
			}

			&.last {
				float: right;
				margin-right: 3px;
			}

			span.dark {
				color: #19395c;
			}
		}



	}
}

.main-header {
	position: relative;
	z-index: 2;
	background: rgb(235,235,235);
	background: linear-gradient(0deg, rgba(235,235,235,1) 0%, rgba(255,255,255,1) 100%);
	border-radius: 12px;
	padding-right: .2rem;
	.nav-item {
		display: inline-block;
		font-size: 1.5rem;
		font-weight: 900;
		text-transform: uppercase;
		color: #000;
		padding: 2rem 1.5rem;
		transition: .3s ease-out;
		&:hover { color: $navy; }
	}
	.menu {
		li {
			border-left: 1px groove #e0e0e0;
			&:nth-child(1) {
				border-left: none;
				.nav-item { padding-left: 0; }
			}
			//&:nth-child(2) { border-left: 1px solid #fff; }
		}
	}
}
.utility-header {
	position: relative;
	z-index: 1;
	background: rgb(39,71,93);
	background: linear-gradient(0deg, rgba(39,71,93,1) 0%, rgba(55,95,125,1) 100%);
	border-radius: 10px;
	padding-top: 1rem;
	margin-top: -.95rem;
	.shader {
		margin: .25rem;
		border-radius: 6px;
		background: rgb(255,255,255);
		background: linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(255,255,255,0.15) 100%);
	}
	.menu { padding: .65rem 0;
		li { margin-right: 1.2rem;
			&:last-child { margin-right: 0; }
		}
		a {
			color: #fff;
			font-size: .9rem;
			font-weight: 600;
			text-transform: uppercase;
			padding: .2rem 0;
			border-bottom: 2px solid transparent;
			transition: .3s ease-in-out;
			&:hover { border-color: rgba(#fff, .4); }
		}
	}
}

.covid-header {
	position: relative;
	z-index: 0;
	background: #f9d549; // 249,213,73
	background: linear-gradient(0deg, rgba(249,213,73,1) 0%, darken(#f9d549, 10) 100%);
	border-radius: 0 0 10px 10px;
	padding-top: 1rem;
	margin-top: -.95rem;
	.shader {
		margin: .25rem;
		border-radius: 6px;
		background: rgb(255,255,255);
		background: linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(255,255,255,0.15) 100%);
	}
	.content { padding: .65rem 0;
		color: #454545;
		font-size: 1.1rem;
		font-weight: 900;
		text-transform: uppercase;
		font-family: Montserrat, Sans-Serif;
		padding: .4rem 2rem .4rem 0 ;
		a {
			color: #454545;
			transition: .3s ease-in-out;
			&:hover { color: rgba(35,35,35,0.8) }
		}
	}
}




/* Home hero */
.wrapper-hero-home {
	position: relative;
	padding: 1rem 0 4rem;
	background-color: $navy;
	min-height: 800px;
	overflow: hidden;
	.grid-container { position: relative; }
	.bg-img {
		background-position: 50% 33%;
		background-size: cover;
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		animation: heroZoomOut 6s ease;
	}
	.hero-content {
		position: relative;
		background-image: url('../../img/layout/hero-gradient.png');
		background-position: center;
		background-size: auto 100%;
		padding: 1rem 0 2rem;
		margin: 12rem 0 10rem;
	}
	.hero-title {
		font-weight: 900;
		font-size: 4.8rem;
		font-style: italic;
		line-height: 1;
		color: #fff;
		text-shadow: 0px 0px 9px rgba(#000,.5);
		margin-bottom: 1rem;
		letter-spacing: -3px;
	}
	.kelloggs-logo { max-height: 80px; }
}

@keyframes heroZoomOut {
	0% { opacity: 0; transform: scale(1.05); }
	25% { opacity: 1; }
  100% { transform: scale(1); }
}

/* Mobile header */
.wrapper-header-mobile {
	position: relative;
	border-top: 22px solid #262626;
	border-bottom: 4px solid $navy-dark;
	background-color: #fff;
	.logo {
		position: relative;
		z-index: 11;
		display: inline-block;
		margin-bottom: -2rem;
	}
	.btn-orange {
		font-size: .9rem;
		padding: .7rem .9rem;
	}
}

/* Mobile Manu */
.wrapper-menu-mobile {
	display: none;
	position: absolute;
	top: 72px;
	z-index: 10;
	background-color: rgba($navy-dark, .95);
	width: 100%;
	padding: 1.2rem 1.2rem .5rem;
	box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.3);
	.mobile-nav {
		margin: 0;
		list-style: none;
		li { border-bottom: 1px solid rgba(255,255,255,.15);
			&:last-child { border-bottom: 0; }
		}
		a {
			position: relative;
			color: #fff;
			font-weight: 600;
			font-size: 1.05rem;
			display: block;
			padding: .8rem .15rem;
			line-height: 1;
			&:after {
				content: '';
				position: absolute;
				top: .88rem; right: .3rem;
				width: 0;
				height: 0;
				border-top: 6px solid transparent;
				border-left: 8px solid $orange;
				border-bottom: 6px solid transparent;
				transition: .25s ease-out;
			}
		}
	}
}

/* Footer */
.wrapper-footer {
	background-color: #fff;
	padding: 1.5rem 0;
	.sep { border-left: 1px solid #e6e5e6; }
	.logo-strip { padding-bottom: 1rem; }
	.footer-links {
		list-style: none;
		margin: 0;
		padding: .8rem 0 .8rem 1rem;
		border-top: 1px solid #e6e5e6;
		border-bottom: 1px solid #e6e5e6;
		li {
			display: inline-block;
			margin-right: .8rem;
			&:last-child { margin-right: 0; }
		}
		a {
			display: inline-block;
			color: #393939;
			font-weight: 600;
			font-size: .875rem;
			border-bottom: 2px solid transparent;
			transition: .3s ease-in-out;
			&:hover {
				border-color: rgba(#393939, .3);
			}
		}
	}
	.copyright {
		padding: 1rem 0 .5rem 1rem;
		p {
			font-size: .875rem;
			font-weight: 600;
			color: rgba(#393939, .45);
			margin-bottom: 0;
		}
	}
}
