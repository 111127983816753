// main: ../main.scss

/* Small only */
@media screen and (max-width: 39.9375em) {

  .title-main {
    font-size: 2rem;
    letter-spacing: -1px;
    margin-bottom: 1rem;
  }

  .wrapper-hero-home { padding-bottom: 2rem;
    .bg-img { height: 600px; bottom: unset; animation: none;
      &:after {
        content: '';
        height: 50px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background: rgb(47,84,107);
        background: linear-gradient(0deg, rgba(47,84,107,1) 5%, rgba(255,255,255,0) 100%);
      }
    }
    .hero-title { font-size: 3.2rem; }
    .hero-content { margin-top: 0; margin-bottom: 22rem; }
    .kelloggs-logo { max-height: 48px; }
    .hero-cards { margin-top: -6.5rem; }
  }

  .play-button { font-size: 1.35rem; max-width: 220px; padding-left: 70px; padding-top: .5rem;
    .play-icon { height: 55px; width: 55px;
      &:after {
        top: 1rem;
        left: 1.4rem;
        border-top: 12px solid transparent;
        border-left: 20px solid $orange;
        border-bottom: 12px solid transparent;
      }
    }
  }

  .card-feature {
    .title {
      font-size: 1.5rem;
      // padding-left: 35px;
      // min-height: 27px;
      // &:before {
      // 	width: 25px;
      // 	height: 27px;
      // 	top: -1px;
      // }
    }
    .article-title { font-size: 1.6rem; }
    p { font-size: .95rem; }
    small { font-size: .85rem; }
    .btn-more { font-size: .8rem; padding-bottom: .8rem;
      &:after { top: .5rem; }
    }
  }

  .dropdown {
    font-size: .98rem;
    height: 40px;
    margin-bottom: 1rem;
  }

  .btn-orange {
    font-size: 1rem;
    padding: .8rem 1.2rem;
    &--large {
      font-size: 1.2rem;
    }
  }

  .btn-grey { font-size: 1.1rem; margin-top: 1.2rem; }

  .btn-navy { font-size: 1.1rem; }

  .wrapper-info {
    background-image: none;
    background-color: $navy;
    padding: 1rem 1.5rem 2rem;
    text-align: center;
    .diagonal {
      &:before { display: none; }
    }
    .cell { padding-top: 0; padding-bottom: 0; }
    p { font-size: 1.25rem; }
    .grid-container {
      padding: 1rem 0;
      border-top: 2px solid rgba(#fff,.2);
      border-bottom: 2px solid rgba(#fff,.2);
    }
  }

  .wrapper-coaches {
    background-image: none;
    background-color: #ffcf32;
    padding: 1rem 1.5rem 2rem;
    text-align: center;
    .diagonal {
      &:before { display: none; }
    }
    .cell { padding-top: 0; padding-bottom: 0; }
    p { font-size: 1.25rem; }
    .grid-container {
      padding: 1rem 0;
      border-top: 2px solid rgba($navy,.2);
      border-bottom: 2px solid rgba($navy,.2);
    }
    .btn-green {  margin-top: 1.5rem; margin-bottom: 1rem;}
  }

  .covid-header {
    position: relative;
    z-index: 0;
    border-radius: 0 ;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: -4px;
    .shader {
      margin: .25rem;
      border-radius: 6px;
    }
  }


  .boxes-display {
    margin: 0;
    background-color: $navy;
    padding-top: 1rem;
    img { max-height: 152px; }
  }

  .wrapper-footer {
    .sep { border-left: 0; }
  }

  .main-container {
    margin: 1rem 0 2rem;
    padding: 2rem 1.5rem 1.5rem;
  }

  .steps-header { margin-bottom: 1rem; }

  .step-content { padding: 0;
    label, p { font-size: 1.2rem; }
    input, textarea, select { font-size: 1.1rem; padding-left: .5rem; }
    input { height: 42px; }
    select { height: 42px; background-size: 15px; }
    .cta { font-size: 1.05rem; }
    .title--large { font-size: 2.2rem; }
  }

  .competition-container { padding: 1rem .5rem 2rem;
    .comp-icon { max-height: 160px; margin-bottom: 1rem;}
  }

  .info-link { margin-bottom: 1rem; font-size: .85rem;
    &:after { font-size: 1.2rem; }
  }

  .wrapper-main-bg { padding-bottom: 2rem; }


  .booking-container {
    .side-sep { border-right: 0; padding-right: 0; }
    .sep { margin: .5rem auto 1.5rem;}
  }

  .booking-form {

    .content-wrapper {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;
    }

    label, .fauxlabel {
      font-size: 1.2rem;
      margin-bottom: 0;
      border-radius: 5px;
      margin-right: 0;
      margin-bottom: .8rem;
      min-height: 0;
      padding: .6rem .8rem;
      &.smaller-text { font-size: 1.2rem; }
      &.small-text { font-size: 1.1rem; padding: .6rem .8rem .8rem .8rem; }
      span.lightwesmall-text { font-size: 0.8rem; display: inline-block; margin-top: -0.25rem}
    }
    .campSelector label {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    input, select {
      font-size: 1.1rem;
      padding-left: .8rem;
      height: 50px;
      margin-bottom: 1.5rem;
    }
    select {
      background-size: 20px;
      padding-right: 1.5rem;
    }
    .help-block { margin-top: -.2rem; margin-bottom: 1.5rem; }
    .form-link { margin-top: -.2rem; margin-bottom: 1.5rem; }
    .padder-left { padding-left: 0; }
    .sportlist { font-size: 1.1rem; }
    .alternate-camps {
      li { font-size: 1.1rem; }
    }
  }

  .kit-order {
     .content-wrapper {
       padding-left: 0px;
       padding-right: 0px;
       margin-left: 0px;

       form#kitorder-form {
         margin-left: 0px;
         margin-right: 0px;
       }

       form#payment-form {
         margin-left: 0.5rem;
         margin-right:0.5rem;
         min-width:225px;
         width:auto;
         padding: 0.25rem 0.1rem 1rem 0.1rem;

       }

     }

    input, select {
      font-size: 1.1rem;
      padding-left: .8rem;
      height: 50px;
      margin-bottom: 1.5rem;
    }
    select {
      background-size: 20px;
      padding-right: 1.5rem;
    }


  }

  .order-summary { margin-top: 1.5rem; }

  .steps-header--booking { margin-bottom: 1rem; }

  .booking-title { font-size: 1.5rem; }

  .form-header { margin-bottom: 2rem;
    .heading { font-size: 1rem; margin-bottom: 0; }
    .req { font-size: .85rem; letter-spacing: -1px; }
  }

  .booking-proceed { padding-top: 2rem; margin-top: 2rem; }

  .info-alert { font-size: .95rem; padding: 1rem 1rem 1rem 4rem;
    &:before { font-size: 2.2rem; top: 1rem; left: 1rem; }
    .button {
      display:block;
      max-width: fit-content;
    }
  }

  .checkbox-container {
    font-size: 1.3rem !important;
  }

  .small-text-right { text-align: right; }

  .form-edit {
    .edit-header {
      .heading { font-size: 1.2rem; }
    }
  }

  .wysiwyg {
    h1, h2, h3, h4, h5, h6 { letter-spacing: 0; }
    h1 { font-size: 2rem; }
    h2 { font-size: 1.8rem; }
    h3 { font-size: 1.6rem; }
    h4 { font-size: 1.5rem; }
    h5 { font-size: 1.4rem; }
    h6 { font-size: 1.3rem; }
    p {
      &.lead { font-size: 1.2rem; }
    }
    img {
      width: 100%;
      margin-bottom: 1rem;
      &.right, &.left { float: unset; width: 100%; margin-left: 0; }
    }
  }

}

/* Medium and up */
@media screen and (min-width: 40em) {
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

  .title-main { font-size: 3.2rem; }

  .logo-container { margin-bottom: -70px !important;
    .logo {
      img { max-width: 85% !important; }
    }
  }

  .main-header {
    .nav-item {
      font-size: 0.827rem !important;
      padding: 1.25rem 0.75rem !important;
    }
  }

  .main-container { padding-top: 3rem; }

  .btn-orange {
    font-size: .95rem;
    padding: .8rem 1rem .7rem;
    &--nav {
      font-size: 0.95rem !important;
      padding: .6rem .9rem !important;
    }
    &--large {
      font-size: 1.25rem;
      padding: .8rem 1.2rem;
    }
  }

  .utility-header {
    .menu {
      a { font-size: .85rem; }
    }
  }

  .wrapper-hero-home { padding-bottom: 2rem;
    .hero-title { font-size: 4.2rem; }
    .hero-content { margin-top: 1rem; margin-bottom: 22rem; }
    .kelloggs-logo { max-height: 80px; }
  }

  .play-button { font-size: 2rem; max-width: 350px; padding-left: 100px;
    .play-icon { height: 90px; width: 90px;
      &:after { top: 1.5rem; left: 2rem; }
    }
  }

  .card-feature {
    .content { padding: 1rem 1.2rem; }
    .title { font-size: 1.28rem; margin-bottom: .5rem; }
    .article-title { font-size: 1.3rem; margin-bottom: .3rem; }
    p { font-size: .95rem; margin-bottom: 1rem; }
    small { font-size: .85rem; }
    hr { margin: 1rem auto .5rem; }
    .btn-more { padding-left: 1.2rem; font-size: .8rem; padding-bottom: .6rem;
      &:after { top: .5rem; }
    }
  }

  .dropdown {
    font-size: .98rem;
    height: 40px;
    margin-bottom: .8rem;
  }

  .wrapper-info {
    .title { font-size: 1.6rem; }
    p { font-size: .95rem; }
  }

  .wrapper-footer {
    .logo-strip { padding-bottom: 0; }
  }

  .boxes-display {
    img { max-height: 166px; }
  }

  .step-content { padding: 0;
    label, p { font-size: 1.3rem; }
    input, textarea, select { font-size: 1.15rem; }
    input { height: 50px; }
    select { height: 50px; background-size: 15px; }
    .cta { font-size: 1.3rem; }
    .title--large { font-size: 2.2rem; }
  }

  .booking-container {
    .side-sep { border-right: 0; padding-right: 0; }
  }

  .booking-steps {
    li { font-size: 1.15rem; min-width: 80px;
      span { font-size: 1.8rem; }
    }
  }

  .booking-form {
    label, .fauxlabel { font-size: 1.6rem;
      &.smaller-text { font-size: 1.4rem; }
      &.small-text { font-size: 1.2rem; }
    }
    input, select { font-size: 1.2rem; }
  }

  .order-summary { max-width: 400px; margin-top: 1.5rem; }

  .steps-header--booking { margin-bottom: 2rem; }

  .booking-title { font-size: 1.8rem; }

  .form-header {
    .heading { font-size: 1.5rem; }
  }

  .booking-proceed { padding-top: 2rem; margin-top: 2rem; }


  .kit-order {
    .content-wrapper {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;

      form#kitorder-form {
        margin-left: 0px;
        margin-right: 0px;
      }

      form#payment-form {
        margin-left: 0.5rem;
        margin-right:0.5rem;
        min-width:225px;
        width:auto;
        padding: 0.25rem 0.1rem 1rem 0.1rem;

      }

    }

    input, select {
      font-size: 1.1rem;
      padding-left: .8rem;
      height: 50px;
      margin-bottom: 1.5rem;
    }
    select {
      background-size: 20px;
      padding-right: 1.5rem;
    }


  }

  .wysiwyg {
    h1 { font-size: 2.8rem; }
    h2 { font-size: 2rem; }
    h3 { font-size: 1.8rem; }
    h4 { font-size: 1.6rem; }
    h5 { font-size: 1.5rem; }
    h6 { font-size: 1.4rem; }
  }

}

/* Medium  iphone landscape only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: landscape) {
}

/* max-width 640px, mobile-only styles and landscape */
@media only screen and (min-width: 40.063em) and (max-width: 1024px) and (orientation: landscape) {

  .booking-form {
    label { font-size: 1.6rem;
      &.smaller-text { font-size: 1.4rem; }
      &.small-text { font-size: 1.2rem; }
    }
  }

}

/* ipad pro */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {

  .booking-form {
    label { font-size: 1.6rem;
      &.smaller-text { font-size: 1.4rem; }
      &.small-text { font-size: 1.2rem; }
    }
  }

}

/* Large and up */
@media screen and (min-width: 64em) {
  .wrapper-hero-home {
    .bg-img {
      background-position: top;
      background-size: cover;
    }
  }
}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .wrapper-hero-home {
    .bg-img {
      background-position: center bottom /*14rem*/; /* BIg SCreens */
    }
  }
}

@media screen and (max-width: 1160px) {

  .logo-container { margin-bottom: -180px;
    .logo {
      img { max-width: 80%; }
    }
    .year { display: none; }
  }

  .main-header {
    .nav-item {
      font-size: 1.3rem;
      padding: 1.5rem 1rem;
    }
  }

  .btn-orange {
    &--nav {
      font-size: 1.2rem;
      padding: .9rem 1.2rem;
    }
  }

  .utility-header {
    .menu {
      li { margin-right: .9rem; }
      a { font-size: .78rem; }
    }
  }

}

.EditMode .aos-init { opacity:1 !important; transform: none !important; }

.ContentFrame {
  [data-aos^=fade][data-aos^=fade] { opacity: 1 !important; }
  .wrapper-head { position:static; }
}